<template>
  <div class="all">
    <textarea
      v-model="value"
      class="textarea p-3"
      :placeholder="placeholder"
      :style="{'height': height, 'border': border,'font-size': size, 'border-radius': radius, 'background-color': background}"
    />
    {{ sendValue }}
  </div>
</template>

<script>
export default {
  name: 'Index',
  components: {
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '14px'
    },
    height: {
      type: String,
      default: '96px'
    },
    border: {
      type: String,
      default: '1px solid #E8E8E8'
    },
    radius: {
      type: String,
      default: '15.2377px'
    },
    valueT: {
      type: String,
      default: null
    },
    background: {
      type: String,
      default: '#FFFFFF'
    }
  },
  data () {
    return {
      value: ''
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
      this.$emit('info', this.value)
    }
  },

  created () {
    if (this.valueT !== null) {
      this.value = this.valueT
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables";

.textarea {
  width: 100%;
  box-sizing: border-box;
}
</style>
