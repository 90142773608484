<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full pt-6"
        >
          <div class="text-left flex pl-6 pr-6">
            <div
              class="text-c24 font-c7 w-4/5"
            >
              Prise de mesure
            </div>
            <div class="w-1/5 flex justify-end">
              <icon
                :data="icons.fermer"
                height="30"
                width="30"
                class="cursor-pointer"
                original
                @click="fermer"
              />
            </div>
          </div>

          <div class="text-c18 font-c4 mt-6 bg-white p-6 pb-12">

            <div class="flex text-left mt-6 text-c18 font-c5">
              <div class="w-4/6">Mesure</div>
              <div class="w-2/6 flex text-center">
                <div class="w-1/2">OD</div>
                <div class="w-1/2">OG</div>
              </div>
            </div>


            <div class="flex justify-center text-left mt-6 text-c14 text-60">
              <div class="w-4/6">Ecart pupillaire</div>

              <div class="w-2/6 flex">
                <div class="inputo h-30 w-1/2">
                  <input type="number" v-model="ecart" class="p-1 h-full w-full">
                </div>

                <div class="inputo h-30 w-1/2 ml-6">
                  <input type="number" v-model="ecartG" class="p-1 h-full w-full">
                </div>
              </div>

            </div>

            <div class="flex justify-center text-left mt-6 text-c14 text-60">
              <div class="w-4/6">Hauteur de montage</div>

              <div class="w-2/6 flex">
                <div class="inputo h-30 w-1/2">
                  <input type="number" v-model="hMontage" class="p-1 h-full w-full">
                </div>
                <div class="inputo h-30 w-1/2 ml-6">
                  <input type="number" v-model="hMontageG" class="p-1 h-full w-full">
                </div>
              </div>
            </div>

            <div class="flex justify-center text-left mt-6 text-c14 text-60">
              <div class="w-4/6">Hauteur calibrée</div>

              <div class="w-2/6 flex">
                <div class="inputo h-30 w-1/2">
                  <input type="number" v-model="hCalibre" class="p-1 h-full w-full">
                </div>
                <div class="inputo h-30 w-1/2 ml-6">
                  <input type="number" v-model="hCalibreG" class="p-1 h-full w-full">
                </div>
              </div>
            </div>

            <div class="flex justify-center text-left mt-6 text-c14 text-60">
              <div class="w-4/6">Largeur du calibre</div>

              <div class="w-2/6 flex">
                <div class="inputo h-30 w-1/2">
                  <input type="number" v-model="lCalibre" class="p-1 h-full w-full">
                </div>
                <div class="inputo h-30 w-1/2 ml-6">
                  <input type="number" v-model="lCalibreG" class="p-1 h-full w-full">
                </div>
              </div>
            </div>

            <div class="flex justify-center text-left mt-6 text-c14 text-60">
              <div class="w-4/6">Angle pantoscopique</div>

              <div class="w-2/6 flex">
                <div class="inputo h-30 w-1/2">
                  <input type="number" v-model="aPant" class="p-1 h-full w-full">
                </div>
                <div class="inputo h-30 w-1/2 ml-6">
                  <input type="number" v-model="aPantG" class="p-1 h-full w-full">
                </div>
              </div>
            </div>

            <div class="flex justify-center text-left mt-6 text-c14 text-60">
              <div class="w-4/6">Galbe de la monture </div>

              <div class="w-2/6 flex">
                <div class="inputo h-30 w-1/2">
                  <input type="number" v-model="gMonture" class="p-1 h-full w-full">
                </div>

                <div class="inputo h-30 w-1/2 ml-6">
                  <input type="number" v-model="gMontureG" class="p-1 h-full w-full">
                </div>
              </div>
            </div>

            <div class="flex justify-center text-left mt-6 text-c14 text-60">
              <div class="w-4/6">Distance verre - oeil</div>

              <div class="w-2/6 flex">
                <div class="inputo h-30 w-1/2">
                  <input type="number" v-model="dVerre" class="p-1 h-full w-full">
                </div>

                <div class="inputo h-30 w-1/2 ml-6">
                  <input type="number" v-model="dVerreG" class="p-1 h-full w-full">
                </div>
              </div>
            </div>

            <div class="flex justify-center text-left mt-6 text-c14 text-60">
              <div class="w-4/6">Distance de lecture</div>

              <div class="w-2/6 flex">
                <div class="inputo h-30 w-1/2">
                  <input type="number" v-model="dLecture" class="p-1 h-full w-full">
                </div>

                <div class="inputo h-30 w-1/2 ml-6">
                  <input type="number" v-model="dLectureG" class="p-1 h-full w-full">
                </div>
              </div>
            </div>

            <div class="flex justify-center text-left mt-6 text-c14 text-60">
              <div class="w-4/6">Diamètre</div>

              <div class="w-2/6 flex">
                <div class="inputo h-30 w-1/2">
                  <input type="number" v-model="diametre" class="p-1 h-full w-full">
                </div>

                <div class="inputo h-30 w-1/2 ml-6">
                  <input type="number" v-model="diametreG" class="p-1 h-full w-full">
                </div>
              </div>
            </div>

            <div class="flex justify-center text-left mt-6 text-c14 text-60">
              <div class="w-4/6">Pont</div>

              <div class="w-2/6 flex">
                <div class="inputo h-30 w-1/2">
                  <input type="number" v-model="pont" class="p-1 h-full w-full">
                </div>
                <div class="inputo h-30 w-1/2 ml-6">
                  <input type="number" v-model="pontG" class="p-1 h-full w-full">
                </div>
              </div>
            </div>

            <div
              v-if="error !== null"
              class="mt-6 text-red text-c13"
            >
              {{ error }}
            </div>
          </div>

          <div class="flex">
            <div class="w-full">
              <buton
                label="Enregistrer"
                radius="0px 0px 10px 10px"
                height="55px"
                :charge="charge"
                @oga="continuer"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import ic_agence from '../../assets/icons/agenceGray.svg'
import fermer from '../../assets/icons/fermer.svg'
import http from "../../plugins/https"
import apiroutes from "../../router/api-routes"

export default {
  name: 'Success',
  components: {
    PopupBase,
    buton
  },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      wantToMakeOffer: false,
      icons: {
        ic_agence,
        fermer
      },
      error: null,
      charge: false,
      pont: '',
      diametre: '',
      dLecture: '',
      dVerre: '',
      gMonture: '',
      aPant: '',
      lCalibre: '',
      hCalibre: '',
      hMontage: '',
      ecart: '',

      pontG: '',
      diametreG: '',
      dLectureG: '',
      dVerreG: '',
      gMontureG: '',
      aPantG: '',
      lCalibreG: '',
      hCalibreG: '',
      hMontageG: '',
      ecartG: '',
    }
  },

  created() {
    this.wantToMakeOffer = this.activation
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    logState(answer){
      this.$emit('oga', answer)
    },


    continuer () {
      let obj = {
        pont: this.pont,
        diametre: this.diametre,
        dLecture: this.dLecture,
        dVerre: this.dVerre,
        gMonture: this.gMonture,
        aPant: this.aPant,
        lCalibre: this.lCalibre,
        hMontage: this.hMontage,
        ecart: this.ecart,
        hCalibre: this.hCalibre
      }

      let objG = {
        pont: this.pontG,
        diametre: this.diametreG,
        dLecture: this.dLectureG,
        dVerre: this.dVerreG,
        gMonture: this.gMontureG,
        aPant: this.aPantG,
        lCalibre: this.lCalibreG,
        hMontage: this.hMontageG,
        ecart: this.ecartG,
        hCalibre: this.hCalibreG
      }
      let finaly = {
        od: obj,
        og: objG
      }
      this.charge = true
      http.post(apiroutes.baseURL + apiroutes.mesure,
          {
            id: this.donnes.id,
            clientMeasures: finaly
          })
          .then(response => {
            this.$emit('oga', true)
            console.log(response)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = "Problème de connexion. Veuillez ressayer"
          })
    }

  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 42%;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: #F5F5F5;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    .diviser{
      height: 1px;
      background-color: #DDDDDD;
    }
    .inputo{
      border: 0.5px solid #DCDCE4;
      box-sizing: border-box;
      border-radius: 4px;
    }
    input{
      background-color: transparent;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .button {
        width: 100%;
        font-size: 16px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
